<template>
  <b-container>
    <b-row>
      <b-col cols="12" md="4">
        <validation-provider v-slot="{ errors }" :name="$t('Price')" vid="price" rules="min_value:0">
          <euro-input v-model="product.price" :disabled="disabled" :error-messages="errors" type="number"
            :label="`${$t('Price')}:`"></euro-input>
        </validation-provider>
      </b-col>
      <b-col cols="12" md="4">
        <validation-provider v-slot="{ errors }" :name="$t('Price Currency')" vid="price_multiplier" rules="required">
          <euro-select v-model="product.price_currency" :disabled="disabled" :multiselect-props="currencySelectProps"
             :label="`${$t('Price Currency')}*`" :style="{ minWidth: '15ch' }" :error-messages="errors"></euro-select>
        </validation-provider>
      </b-col>
      <b-col cols="12" md="4">
        <validation-provider v-slot="{ errors }" :name="$t('Price multiplier')" vid="price_multiplier"
          rules="required|min_value:0|max_digits:5">
          <euro-input v-model="product.price_multiplier" :disabled="disabled" :error-messages="errors" type="number"
            :label="`${$t('Multiplier')}*`"></euro-input>
        </validation-provider>
      </b-col>
      <b-col cols="12" md="4">
        <validation-provider v-slot="{ errors }" :name="$t('Month multiplier')" vid="monthly_price_multiplier"
          rules="required|min_value:0|max_digits:5">
          <euro-input v-model="product.monthly_price_multiplier" :disabled="disabled" type="number"
            :error-messages="errors" :label="`${$t('Month multiplier')}*`"></euro-input>
        </validation-provider>
      </b-col>
      <b-col cols="12" md="4">
        <validation-provider v-slot="{ errors }" :name="$t('Quarter multiplier')" vid="trimester_price_multiplierr"
          rules="required|min_value:0|max_digits:5">
          <euro-input v-model="product.trimester_price_multiplier" :disabled="disabled" type="number"
            :error-messages="errors" :label="`${$t('Quarter multiplier')}*`"></euro-input>
        </validation-provider>
      </b-col>
      <b-col cols="12" md="4">
        <validation-provider v-slot="{ errors }" :name="$t('Semester multiplier')" vid="semester_price_multiplierr"
          rules="required|min_value:0|max_digits:5">
          <euro-input v-model="product.semester_price_multiplier" :disabled="disabled" type="number"
            :error-messages="errors" :label="`${$t('Semester multiplier')}*`"></euro-input>
        </validation-provider>
      </b-col>
      <b-col cols="12" md="4">
        <validation-provider v-slot="{ errors }" :name="$t('Year multiplier')" vid="yearly_price_multiplierr"
          rules="required|min_value:0|max_digits:5">
          <euro-input v-model="product.yearly_price_multiplier" :disabled="disabled" type="number"
            :error-messages="errors" :label="`${$t('Year multiplier')}*`"></euro-input>
        </validation-provider>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState("constants", ["countries"]),
    selectableCurrencies() {
      const uniqueCurrencyCodes = new Set();

      return this.countries
        .filter((el) => el.currency_name !== null)
        .reduce((result, el) => {
          if (!uniqueCurrencyCodes.has(el.currency_code)) {
            uniqueCurrencyCodes.add(el.currency_code);
            result.push({
              text: `${el.currency_name} - ${el.currency_code}`,
              value: el.currency_code,
            });
          }
          return result;
        }, []);
    },

    currencySelectProps() {
      return {
        options: this.selectableCurrencies,
        label: "text",
        "track-by": "value",
        multiple: false,
        "allow-empty": false,
        searchable: true,
        "show-labels": false,
      };
    },
  },
};
</script>
