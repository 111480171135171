var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Price'),
      "vid": "price",
      "rules": "min_value:0"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('euro-input', {
          attrs: {
            "disabled": _vm.disabled,
            "error-messages": errors,
            "type": "number",
            "label": "".concat(_vm.$t('Price'), ":")
          },
          model: {
            value: _vm.product.price,
            callback: function callback($$v) {
              _vm.$set(_vm.product, "price", $$v);
            },
            expression: "product.price"
          }
        })];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Price Currency'),
      "vid": "price_multiplier",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('euro-select', {
          style: {
            minWidth: '15ch'
          },
          attrs: {
            "disabled": _vm.disabled,
            "multiselect-props": _vm.currencySelectProps,
            "label": "".concat(_vm.$t('Price Currency'), "*"),
            "error-messages": errors
          },
          model: {
            value: _vm.product.price_currency,
            callback: function callback($$v) {
              _vm.$set(_vm.product, "price_currency", $$v);
            },
            expression: "product.price_currency"
          }
        })];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Price multiplier'),
      "vid": "price_multiplier",
      "rules": "required|min_value:0|max_digits:5"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('euro-input', {
          attrs: {
            "disabled": _vm.disabled,
            "error-messages": errors,
            "type": "number",
            "label": "".concat(_vm.$t('Multiplier'), "*")
          },
          model: {
            value: _vm.product.price_multiplier,
            callback: function callback($$v) {
              _vm.$set(_vm.product, "price_multiplier", $$v);
            },
            expression: "product.price_multiplier"
          }
        })];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Month multiplier'),
      "vid": "monthly_price_multiplier",
      "rules": "required|min_value:0|max_digits:5"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('euro-input', {
          attrs: {
            "disabled": _vm.disabled,
            "type": "number",
            "error-messages": errors,
            "label": "".concat(_vm.$t('Month multiplier'), "*")
          },
          model: {
            value: _vm.product.monthly_price_multiplier,
            callback: function callback($$v) {
              _vm.$set(_vm.product, "monthly_price_multiplier", $$v);
            },
            expression: "product.monthly_price_multiplier"
          }
        })];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Quarter multiplier'),
      "vid": "trimester_price_multiplierr",
      "rules": "required|min_value:0|max_digits:5"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('euro-input', {
          attrs: {
            "disabled": _vm.disabled,
            "type": "number",
            "error-messages": errors,
            "label": "".concat(_vm.$t('Quarter multiplier'), "*")
          },
          model: {
            value: _vm.product.trimester_price_multiplier,
            callback: function callback($$v) {
              _vm.$set(_vm.product, "trimester_price_multiplier", $$v);
            },
            expression: "product.trimester_price_multiplier"
          }
        })];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Semester multiplier'),
      "vid": "semester_price_multiplierr",
      "rules": "required|min_value:0|max_digits:5"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('euro-input', {
          attrs: {
            "disabled": _vm.disabled,
            "type": "number",
            "error-messages": errors,
            "label": "".concat(_vm.$t('Semester multiplier'), "*")
          },
          model: {
            value: _vm.product.semester_price_multiplier,
            callback: function callback($$v) {
              _vm.$set(_vm.product, "semester_price_multiplier", $$v);
            },
            expression: "product.semester_price_multiplier"
          }
        })];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Year multiplier'),
      "vid": "yearly_price_multiplierr",
      "rules": "required|min_value:0|max_digits:5"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('euro-input', {
          attrs: {
            "disabled": _vm.disabled,
            "type": "number",
            "error-messages": errors,
            "label": "".concat(_vm.$t('Year multiplier'), "*")
          },
          model: {
            value: _vm.product.yearly_price_multiplier,
            callback: function callback($$v) {
              _vm.$set(_vm.product, "yearly_price_multiplier", $$v);
            },
            expression: "product.yearly_price_multiplier"
          }
        })];
      }
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }