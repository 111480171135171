<template>
  <validation-observer ref="validationObserver" v-slot="{ handleSubmit }">
    <b-modal id="priceListModal" hide-footer size="lg" @close="$emit('close')" @show="initForm" @hide="$emit('close')">
      <template #modal-header>
        <div class="w-100 h-100 d-flex justify-content-between align-items-center">
          <div>
            <h4 class="mb-2">{{ isCreating ? $t("New price list") : $t("Price list settings") }}</h4>
          </div>
          <div class="d-flex align-items-center">
            <b-button variant="success" class="mr-2" :class="{ 'spinner spinner-light spinner-right': isSaving }"
              @click="handleSubmit(save)">
              {{ $t("Save") }}
            </b-button>
            <b-button @click="closeModal">{{ $t('Cancel') }}</b-button>
          </div>
        </div>
      </template>
      <h3 class="text-center">
        {{
          isCreating ? $t("New price list settings") : `${focusName} ${$t("Price list settings")}`
        }}:
      </h3>
      <b-container>
        <b-row>
          <b-col v-if="isCreating" cols="12" lg="6">
            <validation-provider v-slot="{ errors }" vid="name">
              <euro-input v-model="localPriceList.name" label="Name:" :error-messages="errors"></euro-input>
            </validation-provider>
          </b-col>
          <b-col v-if="isCreating" cols="12" lg="6">
            <validation-provider v-slot="{ errors }" vid="description">
              <euro-input v-model="localPriceList.description" label="Description:" :error-messages="errors"></euro-input>
            </validation-provider>
          </b-col>
          <b-col v-else cols="12" lg="6">
            <euro-select v-model="selectedPriceList" :multiselect-props="priceListmultiselectProps"
              label="Selected price list:" :style="{ minWidth: '15ch' }" @input="changePriceList"></euro-select>
          </b-col>
          <b-col v-if="!isCreating" cols="12" lg="6">
            <euro-input v-model="focusMultiplier" type="number" label="Company multiplier"></euro-input>
          </b-col>
          <b-col cols="12">
            <div class="separator separator-dashed my-6"></div>
          </b-col>
          <b-col cols="12">
            <euro-select v-model="selectedProduct" :multiselect-props="multiselectProps" label="Product Type:"
              :style="{ minWidth: '15ch' }"></euro-select>
          </b-col>
        </b-row>
      </b-container>
      <product-type-price-list-form v-if="activeForm" :disabled="!isCreating" :product.sync="activeForm">
      </product-type-price-list-form>
    </b-modal>
  </validation-observer>
</template>

<script>
import _ from "lodash";

import OrdersProducttypeService from "@/core/services/orders/orders-producttype.service";
import OrdersPriceListService from "@/core/services/orders/orders-pricelist.service";
import companyService from "@/core/services/company/company.service";

import ProductTypePriceListForm from "@/view/components/forms/ProductTypePriceListForm.vue";
import EuroInput from "../inputs/EuroInput.vue";

import icons from "@/core/config/icons";
import { backendErrorSwal } from "@/core/helpers/swal";
import { ValidationObserver, ValidationProvider } from "vee-validate/dist/vee-validate.full";
import { successToast } from '@/core/helpers';
export default {
  components: {
    EuroInput,
    ProductTypePriceListForm,
    ValidationObserver,
    ValidationProvider,
  },

  props: {
    focusName: {
      type: String,
      default: "",
    },

    focus: {
      type: [Number, null],
      default: null,
    },

    priceList: {
      type: [Object, String],
      default: () => { },
    },

    isCreating: {
      type: Boolean,
      default: true,
    },

    companyMultiplier: {
      type: [Number, String],
      default: 1,
    },
  },

  data() {
    return {
      icons,
      apiErrors: null,
      lists: [],
      selectableProducts: [],
      selectablePriceLists: [],
      selectedProduct: null,
      selectedPriceList: null,
      debouncedInit: _.debounce(async () => await this.init(), 100),
      localPriceList: {
        name: "",
        description: "",
        active: true,
        is_default: false,
        product_type_price_list_set: [],
      },
      isSaving: false,
    };
  },

  computed: {
    focusMultiplier: {
      get() {
        return this.companyMultiplier;
      },
      set(val) {
        this.$emit("update:company-multiplier", val);
      },
    },

    priceListmultiselectProps() {
      return {
        options: this.selectablePriceLists,
        label: "text",
        "track-by": "value",
        multiple: false,
        "allow-empty": false,
        searchable: false,
        "show-labels": false,
      };
    },

    multiselectProps() {
      return {
        options: this.selectableProducts,
        label: "text",
        "track-by": "value",
        multiple: false,
        "allow-empty": false,
        searchable: false,
        "show-labels": false,
      };
    },

    activeForm() {
      return this.localPriceList.product_type_price_list_set.find((el) => el.product_type === this.selectedProduct);
    },
  },

  watch: {
    async focus() {
      let list = { ...this.priceList };
      if (typeof this.priceList == "string") {
        try {
          list = await OrdersPriceListService.getOne(this.priceList);
        } catch (err) {
          backendErrorSwal(err?.response?.data?.detail, err);
        }
      }
      this.localPriceList = { ...list };
      this.selectedPriceList = this.localPriceList.id;
    },

    apiErrors: {
      deep: true,
      handler(val) {
        const formObs = this.$refs.validationObserver;
        const newErrors = {
          name: [...(val?.name ?? [])],
          description: [...(val?.description ?? [])],
        };
        formObs.setErrors({ ...newErrors });
      },
    },
  },

  methods: {
    async initForm() {
      await this.debouncedInit();
    },

    changePriceList(id) {
      if (id) {
        this.localPriceList = { ...this.lists.find((el) => el.id === id) };
        return;
      }

      this.localPriceList = { ...this.lists.find((el) => el.is_default) };
    },

    async init() {
      try {
        if (!this?.lists?.length) {
          const r = await OrdersProducttypeService.getProductTypes();
          this.lists = await OrdersPriceListService.getPriceLists();

          this.selectableProducts = r.map((el) => ({ text: el.name, value: el.id }));
          this.selectablePriceLists = this.lists.map((el) => ({ text: el.name, value: el.id }));
          this.selectedProduct = this.selectableProducts?.[0]?.value;
        }

        this.$nextTick(() => {
          if (this.isCreating) {
            const defaultPriceList = _.cloneDeep(this.lists.find((el) => el.is_default));
            this.localPriceList = { ...defaultPriceList };
            this.localPriceList.is_default = false;
          }
          this.selectedPriceList = typeof this.priceList == "string" ? this.priceList : this.priceList?.id;
        });
      } catch (err) {
        backendErrorSwal(err?.response?.data?.detail, err);
      }
    },

    async save() {
      this.isSaving = true;
      const validator = this.$refs.validationObserver;
      if (validator) {
        await validator.reset();
        const isValid = await validator.validate();
        if (!isValid) return;
      }
      try {
        if (this.isCreating) {
          const body = { ...this.localPriceList, id: null };
          body.product_type_price_list_set = body.product_type_price_list_set.map((el) => ({
            ...el,
            id: null,
            price: el.price || null,
          }));
          await OrdersPriceListService.create(body);
          this.lists = await OrdersPriceListService.getPriceLists();
          this.selectablePriceLists = this.lists.map((el) => ({ text: el.name, value: el.id }));
          successToast();
          this.$emit("close-refresh");

          return;
        }
        await companyService.update(
          {
            name: this.focusName,
            price_list: this.localPriceList.id,
            discount: this.focusMultiplier,
          },
          this.focus,
          { focus_view: true },
        );
        await successToast();
        this.$emit("close-refresh");
      } catch (err) {
        if (err?.response?.status === 400) {
          this.apiErrors = err.response.data;
        } else {
          backendErrorSwal(err?.response?.data?.detail, err);
        }
      }
      this.isSaving = false;
    },

    closeModal() {
      this.$bvModal.hide("priceListModal");
    },
  },
};
</script>
