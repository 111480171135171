import API from "../api.service";

export default {
  get PATH() {
    return "orders-pricelist";
  },

  async getPriceLists() {
    const r = await API.get(`${this.PATH}/`);
    return r.data.results;
  },

  async getOne(id) {
    const r = await API.get(`${this.PATH}/${id}/`);
    return r.data;
  },

  async create(body) {
    const r = await API.post(`${this.PATH}/`, body);
    return r;
  },
};
