<template>
  <div>
    <div class="card card-custom">
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">
            {{ $t("Focus manage") }}
            <span class="d-block text-muted pt-2 font-size-sm">{{
              $t("Price list management")
            }}</span>
          </h3>
        </div>
        <div class="card-toolbar">
          <euro-input v-model="searchFilter" :placeholder="$t('Search')" class="min-w-300px mb-0 mr-0" :debounce="300">
          </euro-input>
          <!--<button type="button" class="btn btn-light-primary font-weight-bolder" aria-haspopup="true"
            aria-expanded="false" @click="openCreateModal">
            <span class="svg-icon svg-icon-md">
              <inline-svg src="/media/svg/icons/Shopping/Euro.svg" />
            </span>
            <span>{{ $t("Add price list") }}</span>
          </button>-->
        </div>
      </div>
      <div class="card-body">
        <datatable ref="table" :table-props="tableProps" :total="total" :options.sync="tableOptions"
          :per-page-options="perPageOptions">
          <template #[`cell.name`]="{ item }">
            <div class="d-flex align-items-center">
              <Avatar :avatar-image="item?.logo" :avatar-text="item.name[0]" />
              <div class="ml-3">
                <div class="text-capitalize font-weight-bolder" :style="{ wordBreak: 'break-word' }">
                  {{ item?.name }}
                </div>
                <div class="text-muted font-size-xs">
                  {{ getActiveVat(item.extracompanyvat_set) }}
                </div>
                <div class="text-muted font-size-xs">
                  {{ getActiveFiscalCode(item.companyfiscalid_set) }}
                </div>
                <div v-if="item.description" class="text-muted">
                  {{ item.description }}
                </div>
              </div>
            </div>
          </template>

          <template #[`cell.contact`]="{ item }">
            <div v-if="item.telcontactscompany_set.length">
              <div class="text-muted font-size-sm">
                {{ getMainTel(item.telcontactscompany_set) }}
              </div>
              <div class="text-muted font-size-sm">
                {{ getMainTelKind(item.telcontactscompany_set) }}
              </div>
            </div>
            <div v-if="item.emailcompany_set.length">
              <div class="text-muted font-size-sm">
                {{ getMainEmail(item.emailcompany_set) }}
              </div>
              <div class="text-muted font-size-sm">
                {{ getMainEmailKind(item.emailcompany_set) }}
              </div>
            </div>
          </template>

          <template #[`cell.invite`]="{ item }">
            <div class="font-weight-bold">
              <span class="font-weight-bolder pr-2">{{ $t("Invited") }}:</span>
              {{ item.users_count }}
            </div>
            <div class="font-weight-bold text-muted">
              <span class="font-weight-bolder pr-2">{{ $t("Available") }}:</span>
              {{ item.available_seats }}
            </div>
          </template>

          <template #[`cell.users_count`]="{ item }">
            <div class="font-weight-bold">
              <span class="font-weight-bolder pr-2">{{ $t("Total") }}:</span>
              {{ item.focus_data.employee_count }}
            </div>
            <div class="font-weight-bold text-muted">
              <span class="font-weight-bolder pr-2">{{ $t("On assignments") }}:</span>
              {{ item.focus_data.employee_on_secondments }}
            </div>
          </template>

          <template #[`cell.assignment_count`]="{ item }">
            <span class="label label-xl label-light-primary mr-2 font-weight-bolder">{{ item.focus_data.secondments_count
            }}</span>
          </template>

          <template #[`cell.product_buy`]="{ item }">
            <div class="font-weight-bold">
              <span class="font-weight-bolder pr-2">{{ $t("Subs") }}:</span>
              {{ item.focus_data.active_subscription }}
            </div>
            <div class="font-weight-bold text-muted">
              <span class="font-weight-bolder pr-2">{{ $t("Prods") }}:</span>
              {{ item.focus_data.order_items_count }}
            </div>
          </template>

          <template #[`cell.price_list`]="{ item }">
            <span class="label label-lg font-weight-bold label-light-info label-inline mb-2">{{
              getPriceListName(item.price_list.name) }}</span>
            <div class="text-muted font-size-xs">
              {{ getDiscount(item.discount) }}
            </div>
            <div class="text-muted font-size-xs">
              {{ getPriceListDescription(item.price_list.description) }}
            </div>
          </template>

          <template #[`cell.address`]="{ item }">
            <div v-if="item.companyaddress_set.length" class="d-flex align-items-center">
              <CountryFlag v-b-tooltip.hover :country-iso="item.country_of_establishment"
                :title="extendedCountryName(item.country_of_establishment)" class="mr-3"></CountryFlag>
              <div class="address">
                <span>{{ getMainAddress(item.companyaddress_set) }}</span>
              </div>
            </div>
          </template>

          <template #[`cell.actions`]="{ item }">
            <div v-if="!item.is_administrative_structure" class="d-flex">
              <!--<b-button tooltip="Show price list" class="btn btn-icon btn-light btn-hover-primary btn-sm "
              @click="openPriceListSettingsModal(item)">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/media/svg/icons/Shopping/Dollar.svg" />
              </span>
            </b-button>-->
              <b-button v-b-tooltip.hover :title="$t('View Details')"
                class="btn btn-icon btn-light btn-hover-primary btn-sm mr-2" @click="goToCompany(item)">
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <inline-svg src="/media/svg/icons/Design/Edit.svg" />
                </span>
              </b-button>
              <b-button v-b-tooltip.hover class="btn btn-icon btn-light btn-hover-primary btn-sm mr-2"
                :title="$t('Go to physical person invited')" @click="goToInvitedPerson(item)"><span
                  class="svg-icon svg-icon-md svg-icon-primary">
                  <inline-svg src="/media/svg/icons/Communication/Group.svg" />
                </span>
              </b-button>
              <b-button v-b-tooltip.hover class="btn btn-icon btn-light btn-hover-primary btn-sm"
                :title="$t('Disable All User Invited')" @click="disableMassiveUser(item)"><span
                  class="svg-icon svg-icon-md svg-icon-primary">
                  <inline-svg src="/media/svg/icons/Code/Stop.svg" />
                </span>
              </b-button>
            </div>
          </template>
        </datatable>
      </div>
    </div>
    <PriceListSettingsModal v-bind="editPriceListModalItem" :focus-name="focusName" :is-creating="isCreatingPriceList"
      :company-multiplier.sync="currentMultiplier" @close="closePriceListSettingsModal"
      @close-refresh="closePriceListSettingsModal(true)"></PriceListSettingsModal>
  </div>
</template>

<script>
import icons from "@/core/config/icons";
import CompanyService from "@/core/services/company/company.service";
import PriceListSettingsModal from "@/view/components/modals/PriceListSettingsModal.vue";
import CountryFlag from "@/view/components/CountryFlag.vue";
import Avatar from "@/view/components/avatars/Avatar.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { backendErrorSwal, toggleUserSwal } from "@/core/helpers/swal";
import { successToast } from '@/core/helpers';
import {
  perPageOptions,
  tableOptions,
} from "@/core/config/datatable.config.js";
import eventBus from "@/core/services/event/eventBus";
import { mapGetters } from "vuex";

export default {
  components: {
    PriceListSettingsModal,
    CountryFlag,
    Avatar,
  },

  props: {
    search: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      isCreatingPriceList: false,
      searchFilter: "",
      focusName: "",
      editPriceListModalItem: {},
      editNotificationsModal: {},
      currentMultiplier: 1,
      icons,
      isLoading: false,
      fields: [
        {
          key: "name",
          label: this.$t("Name"),
          sortable: true,
          class: "legal-person-name",
          thClass: ["d-flex"],
        },
        {
          key: "contact",
          label: this.$t("Contact"),
          class: "align-end col-actions",
        },
        {
          key: "invite",
          label: this.$t("Invite"),
          class: "align-end col-actions",
        },
        { key: "users_count", label: this.$t("Employees") },
        { key: "assignment_count", label: this.$t("Assignments") },
        { key: "product_buy", label: this.$t("Purchased") },
        { key: "price_list", label: this.$t("Price List") },
        {
          key: "actions",
          label: this.$t("Actions"),
          class: "align-end col-actions",
        },
      ],
      total: 0,
      tableOptions: tableOptions,
      perPageOptions: perPageOptions,
      TELEPHONE_KINDS: [],
      EMAIL_KINDS: [],
    };
  },
  computed: {
    ...mapGetters("user", ["isInApFocus"]),
    tableProps() {
      return {
        items: this.itemProvider,
        fields: this.fields,
        filter: this.searchFilter,
      };
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("Focus manage") }]);
  },

  methods: {
    openCreateModal() {
      this.isCreatingPriceList = true;
      this.$nextTick(() => {
        this.openPriceListSettingsModal();
      });
    },

    closePriceListSettingsModal(refresh = false) {
      this.editPriceLisModalItem = {};
      this.focusName = "";
      this.currentMultiplier = 1;
      this.isCreatingPriceList = false;
      this.$bvModal.hide("priceListModal");
      if (refresh) {
        const table = this.$refs.focusManageTable;
        if (table) table.refresh();
      }
    },

    openPriceListSettingsModal(focus = null) {
      if (!focus) {
        this.$bvModal.show("priceListModal");
        return;
      }

      const { price_list: priceList, id, name, discount } = focus;
      if (!priceList) backendErrorSwal();
      this.focusName = `${name}`;

      this.editPriceListModalItem = { focus: id, priceList };
      this.currentMultiplier = discount;

      this.$bvModal.show("priceListModal");
    },

    refresh() {
      const table = this.$refs.table;
      if (table) table.refresh();
    },

    itemProvider(ctx, callback) {
      CompanyService.all({
        perPage: ctx.perPage,
        page: ctx.currentPage,
        sortBy: ctx.sortBy || "created_at",
        search: ctx.filter,
        sortDesc: ctx.sortDesc,
        isFocus: true,
        focusView: true,
        fields:
          "id,discount,logo,name,description,telcontactscompany_set,emailcompany_set,country_of_establishment,companyaddress_set,price_list,extracompanyvat_set,companyfiscalid_set,users_count,available_seats,focus_data",
      })
        .then((res) => {
          this.total = res.count;
          callback(res.results);
        })
        .catch(() => callback([]));

      return null;
    },

    extendedCountryName(iso2) {
      const x = this.countries?.find((el) => el.iso == iso2);
      if (!x) return iso2;
      return x.name;
    },

    getMainTel(telephones) {
      if (!telephones.length) {
        return "";
      }
      const tel = telephones.find((el) => el.main);
      const notMain = telephones["0"];
      if (!tel && !notMain) {
        return "";
      }
      return tel?.number || notMain?.number || "";
    },

    getMainTelKind(telephones) {
      if (!telephones.length) {
        return "";
      }
      const tel = telephones.find((el) => el.main);
      const notMain = telephones["0"];
      if (!tel && !notMain) {
        return "";
      }
      return (
        this.TELEPHONE_KINDS.find((el) => el.value == tel?.kind)?.text ||
        this.TELEPHONE_KINDS.find((el) => el.value == notMain?.kind)?.text ||
        ""
      );
    },

    getMainEmail(emails) {
      if (!emails.length) {
        return "";
      }
      const email = emails[0];
      if (!email) {
        return "";
      }
      return email.email;
    },

    getMainEmailKind(emails) {
      if (!emails.length) {
        return "";
      }
      const email = emails.find((el) => el.main);
      if (!email) {
        return "";
      }
      const emailKind = this.EMAIL_KINDS.find((el) => el.value == email.kind);
      if (!emailKind) {
        return "";
      }
      return emailKind.text;
    },

    getMainAddress(addresses) {
      if (!addresses.length) {
        return "";
      }

      if (addresses.length === 1) {
        return addresses[0].address.formatted;
      }

      const address = addresses.find((el) => el.main);
      if (!address) {
        return "";
      }

      return address.address.formatted;
    },
    getActiveFiscalCode(fiscalCodes) {
      if (!fiscalCodes.length) {
        return "";
      }

      const code = fiscalCodes.find((el) => el.is_active);
      if (!code) {
        return "";
      }

      return this.$t("Fiscal Code") + ": " + code.fiscalid;
    },

    getActiveVat(vats) {
      if (!vats.length) {
        return "";
      }

      const vat = vats.find((el) => el.is_active);
      if (!vat) {
        return "";
      }

      return this.$t("Vat") + ": " + vat.vat;
    },

    getPriceListName(priceList) {
      if (!priceList) {
        return "";
      }
      return priceList;
    },

    getPriceListDescription(priceListDescription) {
      if (!priceListDescription) {
        return "";
      }
      return priceListDescription;
    },

    getDiscount(discount) {
      if (!discount) {
        return "";
      }
      return this.$t("Discount ") + discount + "%";
    },

    goToCompany(item) {
      eventBus.$emit("change-focus", item.id, {
        name: "detail-legal-person",
        params: { companyID: item.id },
      });
    },

    goToInvitedPerson(item) {
      if (this.isInApFocus) {
        this.$router.push({
          name: "administration-users-manage",
          params: { filter: item.name },
        });
      } else {
        eventBus.$emit("change-focus", 1, {
          name: "administration-users-manage",
          params: { filter: item.name },
        });
      }
    },

    async disableMassiveUser(item) {
      const res = await toggleUserSwal("disabled");
      if (res.isConfirmed) {
        try {
          CompanyService.deactivateFocusUser(item.id);
          successToast();
        } catch (err) {
          backendErrorSwal(err, err?.response?.detail);
        }
      }
      //alert("to do blocco massivo " + item.name);
    },
  },
};
</script>

<style scoped>
.btn.btn-outline-light span {
  color: #fff;
}

.btn.btn-outline-primary a {
  color: var(--primary);
}

.btn.btn-outline-light i::before {
  color: #fff;
}

.btn.btn-outline-light:hover span,
.btn.btn-outline-light:focus span,
.btn.btn-outline-light:hover span,
.btn.btn-outline-light:hover i::before,
.btn.btn-outline-light:focus i::before {
  color: var(--primary);
}

.text-capitalize {
  text-transform: capitalize;
}
</style>
