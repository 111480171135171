import API from "../api.service";

export default {
  get PATH() {
    return "orders-producttype";
  },

  async getProductTypes() {
    const r = await API.get(`${this.PATH}/`);
    return r.data.results;
  },
};
